@charset "utf-8"

:root
  --swiper-pagination-bottom: 0
  --swiper-pagination-color: #00D0A6
  --swiper-pagination-bullet-inactive-opacity: 1
  --swiper-pagination-bullet-inactive-color: #D9DCE5

@import 'npm:swiper/scss'
@import 'npm:swiper/scss/pagination'

.swiper.mobile-only
  .swiper-slide
    height: auto !important

  // TODO: threshold should be configurable
  @media (min-width: 768px)
    overflow: visible !important

    &.desktop-col .swiper-wrapper
      flex-direction: column !important

    .swiper-wrapper
      height: 100% !important

    .swiper-slide
      transform: none !important
      width: auto !important
      flex-shrink: initial !important

    .swiper-pagination
      display: none

.swiper-pagination
  position: relative
  padding: 1rem 0
